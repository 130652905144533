import React, { CSSProperties } from 'react'

import { Link } from '@reach/router'

import styles from './Footer.module.scss'

interface IProps {
  style?: CSSProperties
}
const Footer: React.FC<IProps> = ({ style }) => {
  return (
    <footer className={styles.footer} style={style}>
      <p>
        A Pagimovel® é uma solução desenvolvida pela Resale Tecnologia e
        Serviços S/A, CNPJ 21.129.873/0001-79, inscrita no Creci sob o nª
        029715-J.
      </p>
      <p>
        <Link to='/termo-de-compromisso'>Termos e Condições de Uso</Link> -
        Pagimovel.com.br - Copyright 2022
      </p>
    </footer>
  )
}

export default React.memo(Footer)
