import React from 'react'

import Swiper from 'react-id-swiper'

import analistaIcon from 'assets/images/banners/analista.png'
import carteirasIcon from 'assets/images/banners/carteiras.png'
import clientesIcon from 'assets/images/banners/clientes.png'
import corretoresIcon from 'assets/images/banners/corretores.png'
import gestoresIcon from 'assets/images/banners/gestores.png'
import leiloeirosIcon from 'assets/images/banners/leiloeiros.png'
import next from 'assets/images/next.png'
import prev from 'assets/images/prev.png'

import styles from './LogoSlider.module.scss'

const params = {
  navigation: {
    nextEl: '#nextSlide',
    prevEl: '#prevSlide'
  },
  setWrapperSize: true,
  slidesPerView: 4,
  wrapperClass: styles.slider,
  breakpoints: {
    1600: {
      slidesPerView: 4
    },
    1324: {
      slidesPerView: 3
    },
    1024: {
      slidesPerView: 3
    },
    769: {
      slidesPerView: 2
    },
    480: {
      slidesPerView: 1
    },
    376: {
      slidesPerView: 1
    },
    360: {
      slidesPerView: 1
    }
  }
}

const LogoSlider: React.FC = () => {
  const banners = [
    {
      name: 'analista',
      icon: analistaIcon,
      route: 'http://analista.pagimovel.com.br/'
    },
    {
      name: 'carteiras',
      icon: carteirasIcon,
      route: 'https://carteiras.pagimovel.com.br/'
    },
    {
      name: 'clientes',
      icon: clientesIcon,
      route: 'https://clientes.pagimovel.com.br/welcome'
    },
    {
      name: 'corretores',
      icon: corretoresIcon,
      route: 'https://corretores.pagimovel.com.br/'
    },
    {
      name: 'gestores',
      icon: gestoresIcon,
      route: 'http://gestores.pagimovel.com.br/'
    },
    {
      name: 'leiloeiros',
      icon: leiloeirosIcon,
      route: 'https://leiloeiros.pagimovel.com.br/'
    }
  ]
  return (
    <>
      <h2 className={styles.cardsTitle}>Plataformas Pagimóvel</h2>
      <div className={styles.swiperContainer}>
        <div className={styles.buttonContainer}>
          <button type='button' id='prevSlide' className={styles.button}>
            <img src={prev} className={styles.arrow} alt='Previous' />
          </button>
          <button type='button' id='nextSlide' className={styles.button}>
            <img src={next} className={styles.arrow} alt='Next' />
          </button>
        </div>
        <Swiper {...params}>
          {banners.map(banner => {
            return (
              <div key={banner.name}>
                <button
                  type='button'
                  className={styles.swiperButton}
                  onClick={() => window.open(`${banner.route}`)}
                  title={`Link para ${banner.name}`}
                >
                  <figure className={styles.timestamp}>
                    <img src={banner.icon} alt={banner.name} />
                  </figure>
                </button>
              </div>
            )
          })}
        </Swiper>
      </div>
    </>
  )
}

export default React.memo(LogoSlider)
