import React from 'react'

import classNames from 'classnames'

import { SelectSorting } from 'components'

import styles from './SubjectDropdown.module.scss'
interface IProps {
  errorMessage: string
  hasError: boolean
  options: Array<{ value: string; name: string }>
  value: string
  onChange: (
    e:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void
  className: string
  id: string
  onBlur: (
    e:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void
}
const SubjectDropdown: React.FC<IProps> = ({
  value,
  onChange,
  errorMessage,
  options,
  hasError,
  className,
  id,
  onBlur
}) => {
  const wrapperClass = classNames(styles.subjectDropdown, className, {
    [styles.error]: hasError
  })

  return (
    <div className={wrapperClass}>
      <label className={hasError ? styles.titleError : styles.title}>
        Assunto*
        <SelectSorting
          id={id}
          onChange={onChange}
          value={value || ''}
          color={hasError ? 'error' : 'primary'}
          type='outline'
          iconPosition='right'
          fontSize='small'
          options={options}
          onBlur={onBlur}
          style={{}}
        />
      </label>
      {hasError && <span id={styles.spanError}>{errorMessage}</span>}
    </div>
  )
}
export default React.memo(SubjectDropdown)
