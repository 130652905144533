import React from 'react'

import { HeaderBanner, Burger } from 'components'

import styles from './Header.module.scss'

const Header: React.FC = () => {
  return (
    <header className={styles.headerContainer}>
      <HeaderBanner width={183} height={42} />
      <Burger />
    </header>
  )
}

export default React.memo(Header)
