import React, { lazy, Suspense } from 'react'

import { Router, RouteComponentProps, Redirect } from '@reach/router'

const HomePage = lazy(() =>
  import('pages').then(({ HomePage }) => ({ default: HomePage }))
)
const Terms = lazy(() =>
  import('pages').then(({ Terms }) => ({ default: Terms }))
)
const ContactPage = lazy(() =>
  import('pages').then(({ ContactPage }) => ({ default: ContactPage }))
)

const MaintenancePage = lazy(() =>
  import('pages').then(({ MaintenancePage }) => ({ default: MaintenancePage }))
)
const NotFoundPage = lazy(() =>
  import('pages').then(({ NotFoundPage }) => ({ default: NotFoundPage }))
)
const renderLoader = () => <></>
require('dotenv/config')
console.log(process.env.REACT_APP_MAINTENANCE)
export default function MainRouter(): JSX.Element {
  const RouterPage = (
    props: {
      pageComponent: JSX.Element
    } & RouteComponentProps
  ) => props.pageComponent

  return (
    <Suspense fallback={renderLoader()}>
      <Router>
        {process.env.REACT_APP_MAINTENANCE ? (
          <>
            <RouterPage path='/' pageComponent={<MaintenancePage />} />
            <Redirect noThrow from='/*' to='/' />
          </>
        ) : (
          <>
            <RouterPage path='/' pageComponent={<HomePage />} />
            <RouterPage
              path='/termo-de-compromisso'
              pageComponent={<Terms />}
            />
            <RouterPage path='/fale-conosco' pageComponent={<ContactPage />} />
            <RouterPage path='*' pageComponent={<NotFoundPage />} />
          </>
        )}
      </Router>
    </Suspense>
  )
}
