import React from 'react'

import logoBB from 'assets/svg/logo-bb.svg'
import logoEmgea from 'assets/svg/logo-emgea.svg'

import styles from './CheckDisputes.module.scss'

const CheckDisputes: React.FC = () => {
  return (
    <section className={styles.disputesContainer}>
      <div className={styles.info}>
        <h3>Disputas</h3>
        <p>
          Clique no vendedor para consultar o resultado das disputadas
          realizadas.
        </p>
      </div>
      <div className={styles.portalContainer}>
        <article className={styles.portal}>
          <img src={logoEmgea} alt='portal emgea' />
          <a
            href='http://pagimovel.com.br/documents/Classifica%C3%A7%C3%A3o%20Disputa%20CPR202000001%20-%20Emgea%20-%20v.2.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Consultar Classificação
          </a>
        </article>
        <span></span>
        <article className={styles.portal}>
          <img src={logoBB} alt='portal bb' />
          <a
            href='https://seuimovelbb.com.br/'
            target='_blank'
            rel='noreferrer'
          >
            Consultar Classificação
          </a>
        </article>
      </div>
    </section>
  )
}

export default React.memo(CheckDisputes)
