import React from 'react'

import { Toast } from 'components'

import MainRouter from './router'
import 'assets/styles/globals.scss'

const App: React.FC = () => {
  return (
    <div>
      <Toast />
      <MainRouter />
    </div>
  )
}

export default App
