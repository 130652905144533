import React from 'react'

import { Link } from '@reach/router'

import styles from './TopMenu.module.scss'
export interface IProps {
  open: boolean
}
const TopMenu: React.FC<IProps> = ({ open = false }) => {
  const navMenu = [
    { name: 'Home', route: '/' },
    { name: 'Fale Conosco', route: '/fale-conosco' }
  ]
  return (
    <nav className={styles.navContainer}>
      <ul className={open ? styles.activeUl : ''}>
        {navMenu.map(item => (
          <li key={item.name}>
            <Link
              to={item.route}
              getProps={({ isCurrent }) => {
                return {
                  className: `${styles.link} ${isCurrent && styles.active}`
                }
              }}
            >
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default React.memo(TopMenu)
