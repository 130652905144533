import React from 'react'

import { ToastContainer, toast } from 'react-toastify'

import styles from './Toast.module.scss'
import 'react-toastify/dist/ReactToastify.css'

const ToastComponent = () => {
  return (
    <ToastContainer
      position='top-center'
      autoClose={3000}
      toastClassName={styles.toast}
    />
  )
}

export const toastSuccess = (msg: string): React.ReactText =>
  toast.success(msg, {
    progressClassName: styles.success
  })

export const toastError = (msg: string): React.ReactText =>
  toast.error(msg, {
    progressClassName: styles.error
  })

export default React.memo(ToastComponent)
