import React from 'react'

import styles from './Title.module.scss'

export interface IProps {
  children?: string | React.FC
  style?: React.CSSProperties
}

const Title: React.FC<IProps> = ({ children, style }) => {
  return (
    <p className={styles.styledTitle} style={style}>
      {children}
    </p>
  )
}

export default React.memo(Title)
