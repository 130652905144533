import * as React from 'react'
import { useState } from 'react'

import { TopMenu } from 'components'

import styles from './Burger.module.scss'
const Burger: React.FC = () => {
  const [isOpen, toggleOpen] = useState(false)

  return (
    <>
      <div
        className={styles.burgerContainer}
        onClick={() => toggleOpen(!isOpen)}
      >
        <div className={isOpen ? `${styles.active}` : ''}></div>
        <div className={isOpen ? `${styles.activeMid}` : 'mid'}></div>
        <div className={isOpen ? `${styles.activeLast}` : 'last'}></div>
      </div>
      <TopMenu open={isOpen} />
    </>
  )
}

export default React.memo(Burger)
