import React, { InputHTMLAttributes, memo } from 'react'

import classNames from 'classnames'

import styles from './Input.module.scss'
type ReactInputType =
  | React.ChangeEvent<HTMLInputElement>
  | React.ChangeEvent<HTMLTextAreaElement>

interface maskProps extends InputHTMLAttributes<HTMLInputElement> {
  onChange: (e: ReactInputType) => void
  autoComplete?: string
  placeholder?: string
  mask: string
  id: string
}
const MaskComponent: React.FC<maskProps> = ({
  onChange,
  autoComplete = 'off',
  placeholder,
  ...base
}) => {
  const beforeOnChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const applyMask = (currentValue: string, mask = base.mask) => {
      const maxAllowedLength = (mask.match(/\*/g) || []).length
      const onlyNumbers = currentValue.replace(/[^0-9]/g, '')

      if (onlyNumbers.length > maxAllowedLength) {
        return currentValue.slice(0, currentValue.length - 1)
      }

      const formatedValue = []
      let valueIndex = 0
      let maskIndex = 0
      while (valueIndex < onlyNumbers.length) {
        if (mask[maskIndex] === '*') {
          formatedValue.push(onlyNumbers[valueIndex])
          valueIndex += 1
        } else {
          formatedValue.push(mask[maskIndex])
        }
        maskIndex += 1
      }
      if (
        maxAllowedLength === onlyNumbers.length &&
        mask[mask.length - 1] !== '*'
      ) {
        formatedValue.push(mask[mask.length - 1])
      }
      return formatedValue.join('')
    }
    const newValue = applyMask(e.target.value)
    e.target.value = newValue
    onChange(e)
  }
  return (
    <input
      className={styles.input}
      type='text'
      name={base.id}
      placeholder={placeholder}
      onChange={beforeOnChange}
      autoComplete={autoComplete}
      {...base}
    />
  )
}

interface normalInputProps {
  rows: number
  round?: boolean
  icon?: string
  className: string
  autocomplete?: string
  placeholder?: string
  id: string
  mask?: string
}
const NormalInput: React.FC<normalInputProps> = memo(
  ({
    rows,
    icon,
    round = false,
    className,
    autocomplete = 'off',
    placeholder,
    ...base
  }) => {
    const classesNamesInput = classNames(styles.input, className, {
      [styles.icon]: icon,
      [styles.round]: round
    })

    return (
      <div className={icon && styles.inputWrapper}>
        {rows > 0 ? (
          <textarea
            rows={rows}
            className={classesNamesInput}
            name={base.id}
            {...base}
          />
        ) : (
          <input
            className={classesNamesInput}
            name={base.id}
            placeholder={placeholder}
            autoComplete={autocomplete}
            {...base}
          />
        )}
      </div>
    )
  }
)

NormalInput.displayName = 'NormalInput'

interface newInputProps {
  rows: number
  errorMessage: string
  onBlur: () => void
  onFocus?: () => void
  hasError: string | boolean
  round?: boolean
  id: string
  label: string
  mask: string
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void
  placeholder?: string
  type: string
  value: string
  icon?: string
  onClear?: () => void
  className: string
  labelClassName: string
  autocomplete?: string
}

const Input: React.FC<newInputProps> = ({
  errorMessage,
  hasError,
  label,
  labelClassName,
  className,
  autocomplete = 'off',
  placeholder,
  ...base
}) => {
  const classesInput = classNames(className, styles.wrapper, {
    [styles.error]: hasError
  })

  const maskProps = { ...base }
  delete maskProps.onClear
  return (
    <div className={classesInput}>
      {label && (
        <label
          htmlFor={base.id}
          className={classNames(styles.label, labelClassName)}
        >
          {label}
        </label>
      )}
      {base.mask && base.mask.length > 0 ? (
        <MaskComponent
          placeholder={placeholder}
          {...maskProps}
          autoComplete={autocomplete}
          className={classNames(styles.input, className)}
        />
      ) : (
        <NormalInput
          placeholder={placeholder}
          autocomplete={autocomplete}
          className={className}
          {...base}
          mask={undefined}
        />
      )}
      {hasError && <span className={styles.span}>{errorMessage}</span>}
    </div>
  )
}
export default React.memo(Input)
