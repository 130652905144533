import React from 'react'

import styles from './Loading.module.scss'

export interface IProps {
  size: 'small' | 'medium'
}

const Loading: React.FC<IProps> = ({ size }) => (
  <span className={`${styles.loader} ${styles[size]}`} />
)

export default React.memo(Loading)
