import React, { CSSProperties } from 'react'

import classNames from 'classnames'

import styles from './SelectSorting.module.scss'

type typeOptions = { outline: 'outline'; filled: 'filled' }

interface IProps {
  color: string
  type: keyof typeOptions
  style: CSSProperties
  iconPosition?: string
  fontSize?: string
  onChange: (
    e:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void
  value: string
  options: Array<{ value: string; name: string }>
  id: string
  onBlur: (
    e:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => void
}

const SelectSorting: React.FC<IProps> = ({
  type = 'outline',
  style,
  value,
  onChange,
  options,
  color = 'primary',
  id = 'select',
  onBlur
}) => {
  function isPrimary(color: string): Record<string, unknown> {
    if (color === 'primary') {
      return {
        [styles.primaryBackground]: type === 'filled',
        [styles.primaryBorder]: type === 'outline'
      }
    } else {
      return {
        [styles.errorBackground]: type === 'filled',
        [styles.errorBorder]: type === 'outline'
      }
    }
  }
  return (
    <span>
      <select
        id={id}
        value={value}
        onChange={onChange}
        className={classNames(
          styles.outline,
          styles.directionRight,
          styles.fontSmall,
          isPrimary(color)
        )}
        style={style}
        onBlur={onBlur}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
    </span>
  )
}

export default React.memo(SelectSorting)
