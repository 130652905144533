import React from 'react'

import { Link } from '@reach/router'

import logoPagimovel from 'assets/svg/pagimovel-logo.svg'

import styles from './HeaderBanner.module.scss'

export interface IProps {
  width: number
  height: number
}

const HeaderBanner: React.FC<IProps> = props => {
  return (
    <div
      style={{ width: `${props.width}px`, height: `${props.height}px` }}
      className={styles.pagimovelLogoContainer}
    >
      <Link to='/'>
        <img src={logoPagimovel} alt='Logo Pagimovel' />
      </Link>
    </div>
  )
}

export default React.memo(HeaderBanner)
